@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import "node_modules/ng-zorro-antd/ng-zorro-antd.less";

:root {
  --aiomed-night-blue-color: #263558;
  --aiomed-gray-color: #758198;
  --aiomed-grayish-blue-color: #47505A;
  --aiomed-pale-blue-color: #DBDCDD;
  --aiomed-rayola-cplor: #ECEEF2;
  --aiomed-pink-color: #E7424A;
  --aiomed-yellow-color: #F28C16;
  --aiomed-orange-color: #FBB64C;
  --aiomed-green-color: #6BB795;
  --aiomed-white-color: #FFF;
  --aiomed-smoky-white: #F6F6F6;
  --table-red-color: rgba(231, 66, 74, 0.2);
  --table-yellow-color: rgba(255, 143, 38, 0.1);
  --aiomed-font-family: "Lato", sans-serif;
  --aiomed-border-radius-base: 8px;
}

@border-radius-base: var(--aiomed-border-radius-base);
@font-family: var(--aiomed-font-family);
//btn
@btn-border-radius-base: var(--aiomed-border-radius-base);
@btn-border-radius-sm: var(--aiomed-border-radius-base);
@btn-border-width: 1px;
@btn-border-style: solid;
@btn-shadow: 0 2px 2px 4px rgba(0, 0, 0, 0.05);
@btn-default-color: var(--aiomed-night-blue-color);
@btn-default-bg: transparent;
@btn-default-border: var(--aiomed-white-color);
@btn-height-base: 40px;
@btn-padding-horizontal-base: 28px;
@btn-font-size-lg: 18px;
@btn-font-size-sm: 18px;
// inputs
@input-height-base: 35px;
@input-hover-border-color: #7F7B94;
@input-border-color: transparent;
@input-placeholder-color: rgba(37, 42, 54, 0.5);
@input-color: #252A36;
//table
@table-border-radius-base: 0;
@table-bg: var(--aiomed-white-color);
@table-header-bg: rgba(244, 244, 248, 0.8);
@table-header-cell-split-color: none;
@table-padding-vertical: 5px;
@table-padding-horizontal: 16px;
//menu
@menu-bg: var(--aiomed-night-blue-color);
@menu-item-height: 56px !important;
@menu-item-group-height: 44px;
@menu-item-color: rgba(255, 255, 255, 0.6);
@menu-highlight-color: var(--aiomed-white-color);
@menu-item-active-bg: rgba(255, 255, 255, 0.2);
@menu-item-font-size: 18px !important;
@menu-icon-size: 35px !important;
@menu-item-padding: 0 28px !important;
//avatar
@avatar-size-base: 40px;
@avatar-size-lg: 72px;
@avatar-size-sm: 27px;
//alerts
@alert-success-bg-color: var(--aiomed-white-color);
@alert-success-border-color: var(--aiomed-smoky-white);
@alert-error-bg-color: var(--aiomed-white-color);
@alert-message-color: var(--aiomed-grayish-blue-color);
@alert-error-border-color: var(--aiomed-smoky-white);
//tabs
@tabs-ink-bar-color: #303678;
@tabs-title-font-size-lg: 17px;
@tabs-highlight-color: var(--aiomed-night-blue-color);
@tabs-horizontal-padding-lg: 4px 61px 11px 61px;
@tabs-hover-color: var(--aiomed-night-blue-color) !important;
@tabs-active-color: var(--aiomed-night-blue-color) !important;
//Drawer
@drawer-body-padding: 0 0;
@drawer-title-font-size: 25px;

// Layout
@layout-header-background: var(--aiomed-white-color);
@layout-header-padding: 0 44px;
@layout-header-height: 66px;

// Badge

@badge-height: 16px;
@badge-font-size: 10px;

body, nz-content {
  font-family: var(--aiomed-font-family);
  background: var(--aiomed-white-color);
  box-sizing: border-box;
}
